/* Color Variable */
:root {
  --black-color: var(--ui-black);
  --white-color: var(--ui-white);

  --grey-1: #212121;
  --grey-2: #181818;
  --grey-3: #383838;
  --grey-4: #585858;
  --grey-6: #333333;
  --grey-7: #767676;
  --grey-8: #aaa;
  --grey-9: #f3f3f3;
  --grey-10: #f6f6f6;
  --grey-11: #eeeeee;
  --grey-12: #ececec;
  --grey-13: #fafafa;
  --grey-14: #f5f5f5;
  --grey-15: #f9f2f4;
  --grey-16: #eee;
  --grey-17: #f7f7f7;
  --grey-18: #c5cae9;
  --grey-19: #ddd;
  --grey-20: #dae3f3;
  --grey-21: #d5e8f3;
  --grey-22: #c3c3e4;
  --grey-23: #e7f6ff;
  --grey-24: #f1f9fd;
  --grey-25: #555555;
  --grey-26: #777777;
  --grey-27: #4e4e4e;
  --grey-28: #262626;
  --grey-29: #555;
  --grey-30: #444;
  --grey-31: #868686;
  --grey-32: #65798e;
  --grey-35: #546477;
  --grey-36: #55637d;
  --grey-37: #2d3e63;
  --grey-38: #434343;
  --grey-39: #194973;
  --grey-40: #cfddfc;
  --grey-41: #b4b4b4;
  --grey-42: #d2d1d1;
  --grey-43: #e9e9e9;
  --grey-44: #ccc;
  --grey-45: #e5e5e5;
  --grey-46: #bbbbbb;
  --grey-47: #d4d4d5;
  --grey-48: #c6c6c6;
  --grey-49: rgba(0, 0, 0, 0.54);
  --grey-50: rgba(161, 170, 166, 0.5);
  --grey-51: rgba(0, 0, 0, 0.15);
  --grey-53: rgba(255, 255, 255, 0.6);
  --grey-54: rgb(54, 54, 54);
  --grey-55: rgba(255, 255, 255, 0.8);
  --grey-57: #999;
  --grey-58: #ebf4f8;
  --grey-59: #e6e6e6;
  --grey-61: rgb(231, 231, 231);

  --blue-1: #219;
  --blue-2: #337ab7;
  --blue-3: #738bc0;
  --blue-4: #23527c;
  --blue-5: #30426a;
  --blue-6: #577bc9;
  --blue-7: #6b9aff;
  --blue-8: #90ccff;
  --blue-9: #3ea6ff;
  --blue-10: #61b6ff;
  --blue-11: #3ea5ff;
  --blue-12: #41a6ff;
  --blue-14: #357ebd;

  --red-1: #a94442;
  --red-2: #c7254e;
  --red-3: #a11;
  --red-4: #d9534f;
  --red-5: #ff2727;
  --red-7: #f00;

  --green-1: #164;
  --green-2: #1ec863;
  --green-3: #23ae89;

  --orange-1: #e86925;

  --BE-only: var(--ui-warning-7);

  /* Default Theme */
  --bg-card-color: var(--white-color);
  --bg-main-color: var(--white-color);
  --bg-body-color: var(--grey-9);
  --bg-checkbox-border-color: var(--grey-49);
  --bg-sidebar-color: var(--ui-blue-10);
  --bg-sidebar-nav-color: var(--ui-blue-11);
  --bg-widget-color: var(--white-color);
  --bg-widget-header-color: var(--grey-10);
  --bg-widget-table-color: var(--ui-gray-3);
  --bg-header-color: var(--white-color);
  --bg-hover-table-color: var(--grey-14);
  --bg-switch-box-color: var(--ui-gray-5);
  --bg-input-group-addon-color: var(--ui-gray-3);
  --bg-btn-default-color: var(--ui-blue-10);
  --bg-blocklist-hover-color: var(--ui-blue-2);
  --bg-boxselector-color: var(--ui-gray-2);
  --bg-table-color: var(--white-color);
  --bg-md-checkbox-color: var(--grey-12);
  --bg-form-control-disabled-color: var(--grey-11);
  --bg-modal-content-color: var(--white-color);
  --bg-nav-container-color: var(--ui-gray-2);
  --bg-navtabs-hover-color: var(--grey-16);
  --bg-nav-tab-active-color: var(--ui-gray-4);
  --bg-table-selected-color: var(--grey-14);
  --bg-codemirror-color: var(--white-color);
  --bg-codemirror-gutters-color: var(--grey-17);
  --bg-dropdown-menu-color: var(--white-color);
  --bg-log-viewer-color: var(--white-color);
  --bg-log-line-selected-color: var(--grey-18);
  --bg-pre-color: var(--grey-14);
  --bg-blocklist-item-selected-color: var(--grey-12);
  --bg-progress-color: var(--grey-14);
  --bg-pagination-color: var(--ui-blue-3);
  --border-pagination-color: var(--ui-white);
  --bg-pagination-span-color: var(--white-color);
  --bg-pagination-hover-color: var(--ui-blue-3);
  --bg-ui-select-hover-color: var(--grey-14);
  --bg-motd-body-color: var(--grey-20);
  --bg-item-highlighted-color: var(--grey-21);
  --bg-item-highlighted-null-color: var(--grey-14);
  --bg-panel-body-color: var(--white-color);
  --bg-codemirror-selected-color: var(--grey-22);
  --bg-tooltip-color: var(--ui-gray-11);
  --bg-input-sm-color: var(--white-color);
  --bg-app-datatable-thead: var(--grey-23);
  --bg-app-datatable-tbody: var(--grey-24);
  --bg-multiselect-color: var(--white-color);
  --bg-daterangepicker-color: var(--white-color);
  --bg-calendar-color: var(--white-color);
  --bg-calendar-table-color: var(--white-color);
  --bg-daterangepicker-end-date: var(--white-color);
  --bg-daterangepicker-hover: var(--grey-16);
  --bg-daterangepicker-in-range: var(--grey-58);
  --bg-daterangepicker-active: var(--blue-14);
  --bg-input-autofill-color: var(--white-color);
  --bg-btn-default-hover-color: var(--ui-blue-9);
  --bg-btn-focus: var(--grey-59);
  --bg-boxselector-disabled-color: var(--white-color);
  --bg-small-select-color: var(--white-color);
  --bg-stepper-item-active: var(--white-color);
  --bg-stepper-item-counter: var(--grey-61);
  --bg-sortbutton-color: var(--white-color);
  --bg-dashboard-item: var(--ui-blue-3);
  --bg-searchbar: var(--ui-gray-2);
  --bg-inputbox: var(--ui-gray-2);
  --bg-dropdown-hover: var(--ui-gray-3);
  --bg-webeditor-color: var(--ui-gray-3);
  --bg-button-group-color: var(--ui-white);
  --bg-pagination-disabled-color: var(--ui-white);
  --bg-code-script-color: var(--ui-white);
  --bg-stepper-color: var(--ui-white);
  --bg-stepper-active-color: var(--ui-blue-1);
  --bg-code-color: var(--ui-white);

  --text-main-color: var(--grey-7);
  --text-body-color: var(--grey-6);
  --text-widget-header-color: var(--ui-gray-11);
  --text-form-control-color: var(--grey-25);
  --text-muted-color: var(--grey-26);
  --text-link-color: var(--blue-2);
  --text-link-hover-color: var(--blue-4);
  --text-input-group-addon-color: var(--grey-25);
  --text-blocklist-hover-color: var(--grey-37);
  --text-dashboard-item-color: var(--grey-32);
  --text-danger-color: var(--red-1);
  --text-code-color: var(--ui-gray-9);
  --text-navtabs-color: var(--grey-7);
  --text-navtabs-hover-color: var(--grey-6);
  --text-nav-tab-active-color: var(--grey-25);
  --text-cm-default-color: var(--blue-1);
  --text-cm-meta-color: var(--black-color);
  --text-cm-string-color: var(--red-3);
  --text-cm-number-color: var(--green-1);
  --text-codemirror-color: var(--black-color);
  --text-dropdown-menu-color: var(--grey-6);
  --text-log-viewer-color: var(--black-color);
  --text-json-tree-color: var(--blue-3);
  --text-json-tree-leaf-color: var(--blue-5);
  --text-json-tree-branch-preview-color: var(--blue-5);
  --text-pre-color: var(--grey-6);
  --text-blocklist-item-selected-color: var(--grey-37);
  --text-progress-bar-color: var(--grey-27);
  --text-pagination-color: var(--grey-26);
  --text-pagination-span-color: var(--grey-3);
  --text-pagination-span-hover-color: var(--grey-3);
  --text-ui-select-color: var(--grey-6);
  --text-ui-select-hover-color: var(--grey-28);
  --text-summary-color: var(--black-color);
  --text-tooltip-color: var(--white-color);
  --text-rzslider-color: var(--grey-36);
  --text-rzslider-limit-color: var(--grey-36);
  --text-daterangepicker-end-date: var(--grey-57);
  --text-daterangepicker-in-range: var(--black-color);
  --text-daterangepicker-active: var(--white-color);
  --text-input-autofill-color: var(--black-color);
  --text-button-hover-color: var(--grey-6);
  --text-small-select-color: var(--grey-25);
  --text-bootbox: var(--ui-gray-7);
  --text-button-group-color: var(--ui-gray-9);
  --text-button-dangerlight-color: var(--ui-error-5);
  --text-stepper-active-color: var(--ui-blue-8);
  --text-boxselector-header: var(--ui-black);

  --border-color: var(--grey-42);
  --border-widget-color: var(--grey-43);
  --border-sidebar-color: var(--ui-blue-9);
  --border-form-control-color: var(--grey-44);
  --border-table-color: var(--grey-19);
  --border-table-top-color: var(--grey-19);
  --border-datatable-top-color: var(--grey-10);
  --border-blocklist-color: var(--grey-44);
  --border-input-group-addon-color: var(--grey-44);
  --border-btn-default-color: var(--grey-44);
  --border-boxselector-color: var(--grey-6);
  --border-md-checkbox-color: var(--grey-19);
  --border-modal-header-color: var(--grey-45);
  --border-navtabs-color: var(--ui-white);
  --border-codemirror-cursor-color: var(--black-color);
  --border-pre-color: var(--grey-43);
  --border-blocklist-item-selected-color: var(--grey-46);
  --border-pagination-span-color: var(--ui-white);
  --border-pagination-hover-color: var(--ui-white);
  --border-panel-color: var(--white-color);
  --border-input-sm-color: var(--grey-47);
  --border-daterangepicker-color: var(--grey-19);
  --border-calendar-table: var(--white-color);
  --border-daterangepicker: var(--grey-19);
  --border-pre-next-month: var(--black-color);
  --border-daterangepicker-after: var(--white-color);
  --border-modal: 0px;
  --border-sortbutton: var(--grey-8);
  --border-bootbox: var(--ui-gray-5);
  --border-blocklist: var(--ui-gray-5);
  --border-widget: var(--ui-gray-5);
  --border-nav-container-color: var(--ui-gray-5);
  --border-stepper-color: var(--ui-gray-4);

  --shadow-box-color: 0 3px 10px -2px var(--grey-50);
  --shadow-boxselector-color: 0 3px 10px -2px var(--grey-50);
  --blue-color: var(--blue-13);
  --button-close-color: var(--black-color);
  --button-opacity: 0.2;
  --button-opacity-hover: 0.5;

  --bg-image-multiselect: linear-gradient(var(--blue-2), var(--blue-2));
  --bg-image-multiselect-button: linear-gradient(var(--white-color), var(--grey-17));
  --bg-image-multiselect-hover: linear-gradient(var(--white-color), var(--grey-43));
  --border-multiselect: var(--grey-48);
  --border-multiselect-checkboxlayer: var(--grey-51);
  --text-multiselect: var(--grey-29);
  --text-multiselect-selectitem: var(--white-color);
  --bg-multiselect-checkboxcontainer: var(--white-color);
  --text-multiselect-item: var(--grey-30);
  --bg-multiselect-helpercontainer: var(--white-color);
  --text-input-textarea: var(--white-color);

  --user-menu-icon-color: var(--ui-gray-4);
  --sort-icon-muted: var(--ui-gray-5);
  --sort-icon-hover: var(--ui-gray-6);
  --sort-icon: var(--ui-gray-9);
  --border-checkbox: var(--ui-gray-5);
  --bg-checkbox: var(--white-color);
  --border-searchbar: var(--grey-44);
  --bg-button-group: var(--white-color);
  --border-button-group: var(--ui-gray-5);
  --text-button-group: var(--ui-gray-9);
}

/* Dark Theme */
[theme='dark'] {
  --bg-body-color: var(--grey-2);
  --bg-btn-default-color: var(--grey-3);
  --bg-blocklist-hover-color: var(--ui-gray-iron-10);
  --bg-boxselector-color: var(--ui-gray-iron-10);
  --bg-blocklist-item-selected-color: var(--grey-3);
  --bg-card-color: var(--grey-1);
  --bg-checkbox-border-color: var(--grey-8);
  --bg-code-color: var(--grey-2);
  --bg-codemirror-color: var(--grey-2);
  --bg-codemirror-gutters-color: var(--grey-3);
  --bg-codemirror-selected-color: var(--grey-3);
  --bg-dropdown-menu-color: var(--ui-gray-7);
  --bg-main-color: var(--grey-2);
  --bg-sidebar-color: var(--grey-1);
  --bg-sidebar-nav-color: var(--grey-2);
  --bg-widget-color: var(--grey-1);
  --bg-widget-header-color: var(--grey-3);
  --bg-widget-table-color: var(--grey-3);
  --bg-header-color: var(--grey-2);
  --bg-hover-table-color: var(--grey-3);
  --bg-switch-box-color: var(--grey-53);
  --bg-table-color: var(--grey-1);
  --bg-md-checkbox-color: var(--grey-31);
  --bg-form-control-disabled-color: var(--grey-3);
  --bg-modal-content-color: var(--grey-1);
  --bg-nav-container-color: var(--ui-gray-iron-10);
  --bg-navtabs-hover-color: var(--grey-3);
  --bg-nav-tab-active-color: var(--grey-2);
  --bg-table-selected-color: var(--grey-3);
  --bg-log-viewer-color: var(--grey-2);
  --bg-log-line-selected-color: var(--grey-3);
  --bg-pre-color: var(--grey-2);
  --bg-progress-color: var(--grey-3);
  --bg-pagination-color: var(--grey-3);
  --bg-pagination-span-color: var(--grey-1);
  --bg-pagination-hover-color: var(--grey-3);
  --bg-ui-select-hover-color: var(--grey-3);
  --bg-motd-body-color: var(--grey-1);
  --bg-item-highlighted-color: var(--grey-2);
  --bg-item-highlighted-null-color: var(--grey-2);
  --bg-panel-body-color: var(--grey-1);
  --bg-input-group-addon-color: var(--grey-3);
  --bg-tooltip-color: var(--grey-3);
  --bg-input-sm-color: var(--grey-1);
  --bg-service-datatable-thead: var(--grey-1);
  --bg-inner-datatable-thead: var(--grey-1);
  --bg-app-datatable-thead: var(--grey-1);
  --bg-service-datatable-tbody: var(--grey-1);
  --bg-app-datatable-tbody: var(--grey-1);
  --bg-multiselect-color: var(--grey-1);
  --bg-daterangepicker-color: var(--grey-3);
  --bg-calendar-color: var(--grey-3);
  --bg-calendar-table-color: var(--grey-3);
  --bg-daterangepicker-end-date: var(--grey-4);
  --bg-daterangepicker-hover: var(--grey-4);
  --bg-daterangepicker-in-range: var(--ui-gray-warm-11);
  --bg-daterangepicker-active: var(--blue-14);
  --bg-input-autofill-color: var(--grey-2);
  --bg-btn-default-hover-color: var(--grey-4);
  --bg-btn-focus: var(--grey-3);
  --bg-boxselector-disabled-color: var(--grey-54);
  --bg-small-select-color: var(--grey-2);
  --bg-stepper-item-active: var(--grey-1);
  --bg-stepper-item-counter: var(--grey-7);
  --bg-sortbutton-color: var(--grey-1);
  --bg-dashboard-item: var(--grey-3);
  --bg-searchbar: var(--ui-grey-warm-11);
  --bg-inputbox: var(--grey-2);
  --bg-dropdown-hover: var(--grey-3);
  --bg-webeditor-color: var(--ui-gray-iron-10);
  --bg-button-group-color: var(--ui-black);
  --bg-pagination-disabled-color: var(--grey-1);
  --bg-code-script-color: var(--ui-gray-warm-11);
  --bg-stepper-color: var(--ui-gray-iron-10);
  --bg-stepper-active-color: var(--ui-blue-8);

  --text-main-color: var(--white-color);
  --text-body-color: var(--white-color);
  --text-widget-header-color: var(--white-color);
  --text-form-control-color: var(--white-color);
  --text-muted-color: var(--grey-8);
  --text-link-color: var(--blue-9);
  --text-link-hover-color: var(--blue-2);
  --text-input-group-addon-color: var(--grey-8);
  --text-blocklist-hover-color: var(--white-color);
  --text-dashboard-item-color: var(--blue-2);
  --text-danger-color: var(--red-4);
  --text-code-color: var(--white-color);
  --text-navtabs-color: var(--grey-8);
  --text-navtabs-hover-color: var(--grey-9);
  --text-nav-tab-active-color: var(--white-color);
  --text-cm-default-color: var(--blue-10);
  --text-cm-meta-color: var(--white-color);
  --text-cm-string-color: var(--red-5);
  --text-cm-number-color: var(--green-2);
  --text-codemirror-color: var(--white-color);
  --text-dropdown-menu-color: var(--white-color);
  --text-log-viewer-color: var(--white-color);
  --text-json-tree-color: var(--grey-40);
  --text-json-tree-leaf-color: var(--blue-6);
  --text-json-tree-branch-preview-color: var(--blue-7);
  --text-pre-color: var(--white-color);
  --text-blocklist-item-selected-color: var(--white-color);
  --text-progress-bar-color: var(--white-color);
  --text-pagination-color: var(--white-color);
  --text-pagination-span-color: var(--ui-white);
  --text-pagination-span-hover-color: var(--ui-white);
  --text-ui-select-color: var(--white-color);
  --text-ui-select-hover-color: var(--white-color);
  --text-summary-color: var(--white-color);
  --text-boxselector-wrapper-color: var(--white-color);
  --text-tooltip-color: var(--white-color);
  --text-rzslider-color: var(--white-color);
  --text-rzslider-limit-color: var(--white-color);
  --text-daterangepicker-end-date: var(--grey-7);
  --text-daterangepicker-in-range: var(--white-color);
  --text-daterangepicker-active: var(--white-color);
  --text-input-autofill-color: var(--grey-8);
  --text-button-hover-color: var(--white-color);
  --text-small-select-color: var(--grey-7);
  --text-bootbox: var(--white-color);
  --text-button-group-color: var(--ui-white);
  --text-button-dangerlight-color: var(--ui-error-7);
  --text-stepper-active-color: var(--ui-white);
  --text-boxselector-header: var(--ui-white);

  --border-color: var(--grey-3);
  --border-widget-color: var(--grey-1);
  --border-sidebar-color: var(--ui-gray-8);
  --border-form-control-color: var(--grey-54);
  --border-table-color: var(--grey-3);
  --border-table-top-color: var(--grey-3);
  --border-datatable-top-color: var(--grey-3);
  --border-blocklist-color: var(--grey-3);
  --border-input-group-addon-color: var(--grey-38);
  --border-btn-default-color: var(--grey-38);
  --border-boxselector-color: var(--grey-1);
  --border-md-checkbox-color: var(--grey-41);
  --border-modal-header-color: var(--grey-1);
  --border-navtabs-color: var(--grey-38);
  --border-codemirror-cursor-color: var(--white-color);
  --border-pre-color: var(--grey-3);
  --border-blocklist-item-selected-color: var(--grey-38);
  --border-pagination-span-color: var(--grey-1);
  --border-pagination-hover-color: var(--grey-3);
  --border-panel-color: var(--grey-2);
  --border-input-sm-color: var(--grey-3);
  --border-daterangepicker-color: var(--grey-3);
  --border-calendar-table: var(--grey-3);
  --border-daterangepicker: var(--grey-4);
  --border-pre-next-month: var(--white-color);
  --border-daterangepicker-after: var(--grey-3);
  --border-modal: 0px;
  --border-sortbutton: var(--grey-3);
  --border-bootbox: var(--ui-gray-9);
  --border-blocklist: var(--ui-gray-9);
  --border-widget: var(--grey-3);
  --border-pagination-color: var(--grey-1);
  --border-nav-container-color: var(--ui-gray-neutral-8);
  --border-stepper-color: var(--ui-gray-warm-9);

  --blue-color: var(--blue-2);
  --button-close-color: var(--white-color);
  --button-opacity: 0.6;
  --button-opacity-hover: 0.3;
  --shadow-box-color: none;
  --shadow-boxselector-color: none;

  --bg-image-multiselect: linear-gradient(var(--grey-38), var(--grey-38));
  --bg-image-multiselect-button: linear-gradient(var(--grey-1), var(--grey-1));
  --bg-image-multiselect-hover: linear-gradient(var(--grey-3), var(--grey-3));
  --border-multiselect: var(--grey-3);
  --border-multiselect-checkboxlayer: var(--grey-3);
  --text-multiselect: var(--white-color);
  --bg-multiselect-checkboxcontainer: var(--grey-3);
  --text-multiselect-item: var(--white-color);
  --bg-multiselect-helpercontainer: var(--grey-1);
  --text-input-textarea: var(--grey-1);

  --user-menu-icon-color: var(--grey-3);
  --sort-icon-muted: var(--ui-gray-7);
  --sort-icon-hover: var(--ui-gray-6);
  --sort-icon: var(--ui-gray-3);
  --border-checkbox: var(--ui-gray-5);
  --bg-checkbox: var(--white-color);
  --border-searchbar: var(--grey-54);
  --bg-button-group: var(--white-color);
  --border-button-group: var(--ui-gray-5);
  --text-button-group: var(--ui-gray-9);
}

/* High Contrast Theme */
[theme='highcontrast'] {
  --bg-card-color: var(--black-color);
  --bg-main-color: var(--black-color);
  --bg-body-color: var(--black-color);
  --bg-checkbox-border-color: var(--grey-8);
  --bg-sidebar-color: var(--black-color);
  --bg-sidebar-nav-color: var(--black-color);
  --bg-widget-color: var(--black-color);
  --bg-widget-header-color: var(--black-color);
  --bg-widget-table-color: var(--black-color);
  --bg-header-color: var(--black-color);
  --bg-hover-table-color: var(--grey-3);
  --bg-switch-box-color: var(--grey-53);
  --bg-panel-body-color: var(--black-color);
  --bg-dropdown-menu-color: var(--black-color);
  --bg-codemirror-selected-color: var(--grey-3);
  --bg-motd-body-color: var(--black-color);
  --bg-blocklist-hover-color: var(--black-color);
  --bg-blocklist-item-selected-color: var(--black-color);
  --bg-input-group-addon-color: var(--grey-3);
  --bg-table-color: var(--black-color);
  --bg-codemirror-gutters-color: var(--ui-gray-warm-11);
  --bg-codemirror-color: var(--black-color);
  --bg-codemirror-selected-color: var(--grey-3);
  --bg-log-viewer-color: var(--black-color);
  --bg-log-line-selected-color: var(--grey-3);
  --bg-modal-content-color: var(--black-color);
  --bg-form-control-disabled-color: var(--grey-1);
  --bg-input-sm-color: var(--black-color);
  --bg-item-highlighted-color: var(--black-color);
  --bg-service-datatable-thead: var(--black-color);
  --bg-inner-datatable-thead: var(--black-color);
  --bg-app-datatable-thead: var(--black-color);
  --bg-service-datatable-tbody: var(--black-color);
  --bg-app-datatable-tbody: var(--black-color);
  --bg-pagination-color: var(--grey-3);
  --bg-pagination-span-color: var(--ui-black);
  --bg-multiselect-color: var(--grey-1);
  --bg-daterangepicker-color: var(--black-color);
  --bg-calendar-color: var(--black-color);
  --bg-calendar-table-color: var(--black-color);
  --bg-daterangepicker-end-date: var(--grey-3);
  --bg-daterangepicker-hover: var(--grey-3);
  --bg-daterangepicker-in-range: var(--grey-2);
  --bg-daterangepicker-active: var(--blue-14);
  --bg-tooltip-color: var(--black-color);
  --bg-table-selected-color: var(--grey-3);
  --bg-pre-color: var(--grey-2);
  --bg-nav-container-color: var(--ui-black);
  --bg-navtabs-hover-color: var(--grey-3);
  --bg-nav-tab-active-color: var(--ui-black);
  --bg-btn-default-color: var(--black-color);
  --bg-input-autofill-color: var(--black-color);
  --bg-code-color: var(--ui-black);
  --bg-btn-default-hover-color: var(--grey-4);
  --bg-btn-focus: var(--black-color);
  --bg-boxselector-color: var(--black-color);
  --bg-boxselector-disabled-color: var(--black-color);
  --bg-small-select-color: var(--black-color);
  --bg-stepper-item-active: var(--black-color);
  --bg-stepper-item-counter: var(--grey-3);
  --bg-sortbutton-color: var(--grey-1);
  --bg-inputbox: var(--black-color);
  --bg-searchbar: var(--black-color);
  --bg-dropdown-hover: var(--black-color);
  --bg-webeditor-color: var(--ui-gray-warm-9);
  --bg-pagination-disabled-color: var(--ui-black);
  --bg-pagination-hover-color: var(--ui-black);
  --bg-code-script-color: var(--ui-black);
  --bg-stepper-active-color: var(--ui-blue-8);
  --bg-stepper-color: var(--ui-black);

  --text-main-color: var(--white-color);
  --text-body-color: var(--white-color);
  --text-widget-header-color: var(--white-color);
  --text-link-color: var(--blue-9);
  --text-link-hover-color: var(--blue-9);
  --text-danger-color: var(--red-7);
  --text-code-color: var(--red-7);
  --text-form-control-color: var(--white-color);
  --text-blocklist-hover-color: var(--blue-11);
  --text-boxselector-wrapper-color: var(--white-color);
  --text-dashboard-item-color: var(--blue-12);
  --text-muted-color: var(--white-color);
  --text-tooltip-color: var(--white-color);
  --text-blocklist-item-selected-color: var(--blue-9);
  --text-input-group-addon-color: var(--white-color);
  --text-codemirror-color: var(--white-color);
  --text-dropdown-menu-color: var(--white-color);
  --text-log-viewer-color: var(--white-color);
  --text-summary-color: var(--white-color);
  --text-rzslider-color: var(--white-color);
  --text-rzslider-limit-color: var(--white-color);
  --text-pagination-color: var(--white-color);
  --text-daterangepicker-end-date: var(--ui-white);
  --text-daterangepicker-in-range: var(--white-color);
  --text-daterangepicker-active: var(--white-color);
  --text-ui-select-color: var(--white-color);
  --text-json-tree-color: var(--white-color);
  --text-json-tree-leaf-color: var(--white-color);
  --text-json-tree-branch-preview-color: var(--white-color);
  --text-pre-color: var(--white-color);
  --text-navtabs-color: var(--white-color);
  --text-navtabs-hover-color: var(--white-color);
  --text-nav-tab-active-color: var(--white-color);
  --text-input-autofill-color: var(--white-color);
  --text-button-hover-color: var(--white-color);
  --text-small-select-color: var(--white-color);
  --text-pagination-span-color: var(--ui-white);
  --text-bootbox: var(--white-color);
  --text-pagination-span-hover-color: var(--ui-white);
  --text-stepper-active-color: var(--ui-white);
  --text-boxselector-header: var(--ui-white);

  --border-color: var(--grey-55);
  --border-widget-color: var(--white-color);
  --border-sidebar-color: var(--white-color);
  --border-form-control-color: var(--grey-54);
  --border-table-color: var(--grey-55);
  --border-table-top-color: var(--grey-55);
  --border-datatable-top-color: var(--grey-55);
  --border-sidebar-high-contrast: 1px solid var(--blue-9);
  --border-code-high-contrast: 1px solid var(--white-color);
  --border-panel-color: var(--white-color);
  --border-input-group-addon-color: var(--grey-54);
  --border-modal-header-color: var(--grey-3);
  --border-input-sm-color: var(--white-color);
  --border-pagination-color: var(--grey-1);
  --border-pagination-span-color: var(--grey-1);
  --border-daterangepicker-color: var(--white-color);
  --border-calendar-table: var(--black-color);
  --border-daterangepicker: var(--black-color);
  --border-pre-next-month: var(--white-color);
  --border-daterangepicker-after: var(--black-color);
  --border-pre-color: var(--grey-3);
  --border-codemirror-cursor-color: var(--white-color);
  --border-modal: 1px solid var(--white-color);
  --border-blocklist-color: var(--white-color);
  --border-sortbutton: var(--black-color);
  --border-bootbox: var(--black-color);
  --border-blocklist: var(--white-color);
  --border-widget: var(--white-color);
  --border-nav-container-color: var(--ui-white);
  --border-stepper-color: var(--ui-gray-warm-9);

  --shadow-box-color: none;
  --shadow-boxselector-color: none;

  --bg-image-multiselect: linear-gradient(var(--black-color), var(--black-color));
  --bg-image-multiselect-button: linear-gradient(var(--grey-1), var(--grey-1));
  --bg-image-multiselect-hover: linear-gradient(var(--grey-3), var(--grey-3));
  --border-multiselect: var(--black-color);
  --border-multiselect-checkboxlayer: var(--grey-3);
  --text-multiselect: var(--white-color);
  --bg-multiselect-checkboxcontainer: var(--grey-3);
  --text-multiselect-item: var(--white-color);
  --bg-multiselect-helpercontainer: var(--grey-1);
  --text-input-textarea: var(--black-color);
  --bg-item-highlighted-null-color: var(--grey-2);
  --text-cm-default-color: var(--blue-9);
  --text-cm-meta-color: var(--white-color);
  --text-cm-string-color: var(--red-7);
  --text-progress-bar-color: var(--black-color);

  --user-menu-icon-color: var(--white-color);
  --sort-icon-muted: var(--ui-gray-7);
  --sort-icon-hover: var(--ui-gray-6);
  --sort-icon: var(--ui-gray-3);
  --border-checkbox: var(--ui-gray-5);
  --bg-checkbox: var(--white-color);
  --border-searchbar: var(--ui-gray-5);
  --bg-button-group: var(--white-color);
  --border-button-group: var(--ui-gray-5);
  --text-button-group: var(--ui-gray-9);
}
